<template>
  <div>
    <Card>
      <template slot="outer">
        <div class="mb-4 flex justify-between align-center">
          <div class="py-3 inline-flex align-center">
            <md-icon>list</md-icon>
            <h3 class="pl-2 text-uppercase">Study Plan List</h3>
          </div>
          <div class="flex">
           <md-button class="bg-victoria text-white font-bold outline-gray-400 rounded ">
            Course-Wise
           </md-button>
           
           <md-button class="outline-gray-400 rounded ">
            Student Wise
           </md-button>
            <div class="md-layout-item flex justify-end">
            <div class="flex align-center">
              <router-link :to="{name: 'studyPlan.calendar'}"><md-icon>date_range</md-icon></router-link>
              <md-switch
                v-model="boolean"
                class="px-2 m-0"
              ></md-switch>
              <md-icon>format_list_bulleted</md-icon>
            </div>
          </div>
          </div>
        </div>
      </template>
      <CardBody class="p-3">
        <div class="mb-3 flex justify-between flex-wrap justify-sm-center justify-xs-center">
          <SelectComponent placeholder="Select one" class="w-64 m-2" />
          <SearchBox class="p-2 w-64 m-2" />
        </div>
        <md-divider></md-divider>
        <Table :items="planList" @onSelect="onSelect" hover-action>
          <template slot="row" slot-scope="{ item }" md-selectable="single">
            <md-table-cell md-label="SL" md-numeric>{{ item.id }}</md-table-cell>
            <md-table-cell md-label="STUDENT ID" class="text-victoria">{{ item.studentId }}</md-table-cell>
            <md-table-cell md-label="STUDENT NAME" class="text-victoria">{{ item.studentName }}</md-table-cell>
            <md-table-cell md-label="MEETING ID" class="text-victoria">{{ item.meetingId }}</md-table-cell>
            <md-table-cell md-label="DURATION">{{ item.duration }}</md-table-cell>
            <md-table-cell md-label="CREATED BY" class="text-victoria">{{ item.createdBy }}</md-table-cell>
            <md-table-cell md-label="BRANCH">{{ item.branch }}</md-table-cell>
            <md-table-cell md-label="COURSE">{{ item.course }}</md-table-cell>
            <md-table-cell md-label="STATUS" class="text-green">{{ item.status }}</md-table-cell>
            
            <span class="action">
              <md-icon class="bg-default rounded-full p-1 text-gray-700 text-base mr-1">visibility</md-icon>
              <md-icon class="bg-victoria rounded-full p-1 text-white text-base ">edit</md-icon>
            </span>
          </template>
        </Table>
      </CardBody>
    </Card>
  </div>
</template>

<script>
import { Card, CardBody, SelectComponent, Table } from "@/components";
import SearchBox from "@/components/atom/SearchBox";
import data from "@/data/study-plan/data"
import { mapMutations } from "vuex";

export default {
  name: "Todo-List",
  components: {
    Card,
    Table,
    CardBody,
    SelectComponent,
    SearchBox
  },
  data() {
      return {
          boolean: true,
          planList: data.planList
      }
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog",
    }),
    onSelect() {

    }
  },
};
</script>

